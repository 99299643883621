#root {
  #videoslider {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }

  .classicLogo {
    width: 20%;
    height: 20%;
  }

  .discordLogo {
    height: 25px;
    margin-bottom: 10px;
  }

  .App {
    text-align: center;
    color: white;
  }

  .BisList,
  .WCTHeader {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, .4), 0 0 0 1px rgba(255, 255, 255, .04);
    background-color: rgba(0, 0, 0, 0.4) !important;
    border-radius: 4px;
    border: 1px solid #000;
  }

  .WCTHeader h1 {
    color: white !important;
  }

  .BisList {
    padding: 20px;
  }

  .WCTHeader {
    padding: 12px 15px;
    margin-bottom: 20px;
  }

  .BisListFingers > div,
  .BisListTrinkets > div,
  .BisListLeft,
  .BisListCenter,
  .BisListRight,
  .BissListBottom > div,
  .row {
    display: inline-block;
  }
  .BisListFingers,
  .BisListTrinkets {
    max-height: 62px;
  }

  @media only screen and (max-width: 600px) {
    .BisListLeft,
    .BisListRight {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .row {
    max-width: 100%;
  }

  .BisListCenter {
    padding: 5px;
    width: 320px;
    visibility: visible;
    vertical-align: top;
  }

  .Banner {
    padding: 10px;
  }

  .stageSelectionGroup {
    flex-wrap: wrap;
    margin-top: 6px;
    border-top: 1px solid #ffc107;
  }
  .stageSelectionGroup .btn {
    margin-top: 0;
    border-radius: 0;
    border-top: none;
  }



  .qe0 {
    border: 1px outset #9d9d9d;
    box-shadow: inset 0 0 4px #9d9d9d;
  }
  .qe1 {
    border: 1px outset #fff;
    box-shadow: inset 0 0 4px #fff;
  }
  .qe2 {
    border: 1px outset #1eff00;
    box-shadow: inset 0 0 4px #1eff00;
  }
  .qe3 {
    border: 1px outset #0070ff;
    box-shadow: inset 0 0 4px #0070ff;
  }
  .qe4 {
    border: 1px outset #a335ee;
    box-shadow: inset 0 0 4px #a335ee;
  }
  .qe5 {
    border: 1px outset #ff8000;
    box-shadow: inset 0 0 4px #ff8000;
  }

  .tooltip-visible {
    visibility: visible !important;
  }
}
