table {
    width: 100%;

    th, td {
        text-align: left;
    }

    .col-center {
        text-align: center;
    }
}
